import React from 'react';

import './PageNotFound.scss';

class PageNotFound extends React.Component {
	public render(): React.ReactNode {
		return <div className="PageNotFound">404</div>;
	}
}

export default PageNotFound;
