import React from 'react';

import './CV.scss';

class CV extends React.Component {
	public render(): React.ReactNode {
		return (
			<div className="CV">
				<div className="CV__title">CV</div>
				<div className="CV__body">very cool (cool very)</div>
			</div>
		);
	}
}

export default CV;
